import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default ({data}) => (
  <Layout>
    <SEO title="Životní a vzrůstové formy rostlin" />
    <h1>Životní a vzrůstové formy rostlin</h1>

    <p><strong>Životní formy </strong>jsou fyziognomicky a funkčně podobné typy rostlin, které vznikly konvergentním vývojem. Jejich rozdělení je založeno na způsobu umístění a ochrany obnovovacích orgánů – mají stejné adaptivní znaky (efarmonické). Jinak řečeno jde o to, v&nbsp;jaké formě přežívají své nepříznivé období.</p>
    <hr />
    <h2>Raunkiaerův systém</h2>
    <p>Raunkiaer v roce 1905 vyznačil několik druhů rostlin dle životní formy. Raunkiaerova klasifikace rozlišuje:</p>
    <ul>
    <li><strong>fanerofyty,</strong></li>
    <li><strong>chamaefyty,</strong></li>
    <li><strong>hemikryptofyty,</strong></li>
    <li><strong>geofyty</strong></li>
    <li>a <strong>terofyty.</strong></li>
    </ul>
    <p><strong>Fanerofyty</strong> jsou typicky stromy a ve svém nepříznivém období zachovávají reprodukční orgány (pupeny) vysoko nad zemí, kde přežívají v&nbsp;koruně (více než 30 cm nad povrchem). Během tohoto období nesmí být příliš velký vítr a mráz, který by pupeny poškozoval. Nejčastěji se vyskytuje v&nbsp;geobiomu lesů mírného pásma a opadavého deštného lesa.</p>
    <p><strong>Chamaefyty</strong> jsou malé dřevnaté keříčky (mohou být opadavé), které své pupeny nechají přikrýt sněhovou pokrývku, která pak funguje jako izolace chránící reprodukční orgány před vysokými mrazy. Typické jsou pro geobiom tundry.</p>
    <p><strong>Hemikryptofyty</strong> jsou tzv. trvalky s&nbsp;pupeny umístěnými u země. Listoví na konci vegetačního období odumře a spadne na pupeny, tím je přikryje a chrání před mrazem. Jak listoví tlí, zároveň tím uvolňuje i malé množství tepla. Tato forma je nejtypičtější pro geobiomy stepi a lesů mírného pásma.</p>
    <p><strong>Geofyty</strong> na zimu stahují látky pod zem do oddenků či hlíz. Tato forma má velkou konkurenční výhodu, že má hned z&nbsp;kraje vegetačního období velkou zásobu vody a živin a může tak vyrazit oproti jiným formám dříve. Patří sem tzv. jarní efemeroidy, které vykvétají dříve, než se zatáhne listnatý les (poté je v&nbsp;podrostu lesa málo světla). Typickým zástupcem geofytů je cibule či sněženka. Často je mimo lesů mírného pásma nalézáme v&nbsp;geobiomu savan.</p>
    <p>Mezi geofyty patří i invazivní křídlatka.</p>
    <p><strong>Terofyty</strong> jsou tzv. jednoletky, rostlina přes nepříznivé období zahyne a zůstanou po ní na povrchu země jen semena. Na jaře pak vyroste ze semena, obvykle stihne v&nbsp;našich podmínkách vykvést až v&nbsp;2. polovině léta. Nepříznivým obdobím může být například krom zimy i období sucha v&nbsp;tropech.</p>
    <p>Mezi ně patří například invazivka netýkavka malokvětá a žláznatá.</p>
    <hr />
    <h2>Rozšířený systém vzrůstových forem</h2>
    <p>Výše popsaný systém životních forem byl v&nbsp;roce 1974 rozšířen. Podle procentuálního zastoupení různých forem lze pak v&nbsp;porovnání s předešlým systémem lépe charakterizovat skladbu jednotlivých geobiomů. Pánové Ellenberg a Mueller-Dombois popisují následující vzrůstové formy:</p>
    <ul>
    <li>fanerofyty,</li>
    <li>chamaefyty,</li>
    <li>hemikryptofyty,</li>
    <li>geofyty,</li>
    <li><strong>hydrofyty,</strong></li>
    <li>terofyty,</li>
    <li><strong>liány,</strong></li>
    <li><strong>epifyty,</strong></li>
    <li><strong>stromoví hemiparazité,</strong></li>
    <li><strong>thalofyty.</strong></li>
    </ul>
    <p><strong>Hydrofyty</strong> jsou vodní analogií ke geofytům. Pokud však stahuje živiny do části schované v&nbsp;bahně pod hladinou vody, můžeme mluvit o tzv. helofytu.</p>
    <p><strong>Liány </strong>jsou pak popínavé rostliny používající kmen stromu jako oporu pro svůj stonek. Patří mezi ně například psí víno, břečťan popínavý či původní vinná réva.</p>
    <p><strong>Epifyty</strong> „sedí“ v&nbsp;korunách stromů, na větvích a kmenech. Nejde o parazity, vodu a minerály získávají jejich stékáním po kmeni stromu. Vyskytují se především v&nbsp;tropech, u nás jen v&nbsp;podobě mechů a lišejníků, žádné epifytické vyšší rostliny se v&nbsp;našich podmínkách přirozeně nevyskytují.</p>
    <p><strong>Stromoví hemiparazité </strong>berou z&nbsp;těla hostitele vodu, nikoliv však asimiláty (jsou schopni asimilace). Mezi nejznámější zástupce patří v&nbsp;naších podmínkách jmelí či ochmet evropský.</p>
    <p><strong>Thalofyty</strong> nemají cévní svazky a místo kořenů disponují maximálně jen příchytnými orgány. Vodu s živinami přijímají celým povrchem těla a jde o různé mechorosty, lišejníky, řasy a sinice.</p>

    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Životní a vzrůstové formy rostlin</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/uvod/"><button className="inv">&larr; Úvod do ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/zivotni-strategie/"><button className="inv">Životní a populační strategie organismů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
